import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// Constants
import { appUrl } from "../../../constants/url-constants";

// Utils
import dashedLowercase from "../../../utils/helpers/dashed-lowercase";

const SellerPopularCarList = ({
    onClose = () => { }
}) => {
    const BASE_URL = `${appUrl()}/sell-used-car`;
    const cities = ["Dubai", "Abu-dhabi"];
    return (
        <div styleName={"styles.sellLocationModal"}>
            <div styleName={"styles.header"}>
                <span styleName={"styles.close"} onClick={onClose}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M16.2669 5.81512C16.6726 5.40656 16.6726 4.74716 16.2669 4.3386L15.6809 3.74856C15.2712 3.33596 14.6038 3.33596 14.1941 3.74856L6.73314 11.2617C6.32741 11.6702 6.32741 12.3296 6.73314 12.7382L14.1941 20.2513C14.6038 20.6639 15.2712 20.6639 15.6809 20.2513L16.2669 19.6613C16.6726 19.2527 16.6726 18.5933 16.2669 18.1848L10.125 11.9999L16.2669 5.81512Z" fill="#ED6F00" />
                    </svg>
                </span>
                <h4 styleName={"styles.heading"}>SELL USED CAR</h4>
            </div>
            <div styleName={"styles.outer"}>
                <a key={`uae`} href={`${BASE_URL}/`} styleName={"styles.cardTitle"}>
                    Sell Car in UAE
                </a>
                {(cities || []).map((city, index) => (
                    <a key={`${city}${index}`} href={`${BASE_URL}-${dashedLowercase(city)}/`} styleName={"styles.cardTitle"}>
                        Sell Car in {city}
                    </a>
                ))}
            </div>
        </div>
    );
};

SellerPopularCarList.propTypes = {
    onClose: PropTypes.func,
    cities: PropTypes.array,
    allFilters: PropTypes.object,
    clearAllFiltersConnect: PropTypes.func
};

export default SellerPopularCarList;
