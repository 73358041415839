import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { clearAllFilters } from "../filters/actions";
import { reloadCarList } from "../car-listing/actions";
import PopularCarList from "./component";

const mapStateToProps = ({
    filters: {
        allFilters
    },
    cities: {
        selectedCity
    }
}) => ({
    allFilters,
    selectedCity
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    clearAllFiltersConnect: clearAllFilters,
    reloadCarListConnect: reloadCarList
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PopularCarList));
