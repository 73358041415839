/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";

// Styles
import styles from "./styles.css";

// Icons
import mapPing from "./images/map-pin.svg";
import ArrowIcon from "../../shared/arrow";
import NeedHelpIcon from "./images/need-help-icon.svg";
import TestimonialIcon from "./images/testimonial-icon.svg";
import CarComparison from "./images/car-compare.svg";
import MyBookingIcon from "./images/my-booking-icon.svg";
import WishlistIcon from "./images/wishlist-icon.svg";
import SellOrderIcon from "./images/sell-order-icon.svg";
import HowWorkIcon from "./images/how-do-we-work.svg";
import WarrantyIcon from "./images/warranty.svg";
import AppStoreIcon from "./images/app-store-icon.png";
import PlayStoreIcon from "./images/play-store-icon.png";
import WhiteCrossIcon from "../../shared/icon-cross/images/white-cross.svg";
import BlogIcon from "./images/blog-icon.svg";
import OfferIcon from "./images/offer-icon.svg";
import BuyIcon from "./images/buy-icon.svg";
import SellIcon from "./images/sell-icon.svg";
import ServiceContractIcon from "./images/service-contract.svg";
import CarLoanIcon from "./images/car-loan-icon.svg";
import BankValuationReportIcon from "./images/bank-valuation-report-icon.svg";

// Tracking
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import { subscriptionEvents } from "../../../tracking/msite/b2c-subscription-flow-events";

// Utilities
import Filters from "../../../utils/filters-v2";
import useLink from "../../../hooks/use-link";
import { generateCarCareRoute } from "../car-care/utils";

// Components
import PopularCarList from "../popular-car-list";
import LazyImage from "../../shared/lazy-image";
import withWishlist from "../with-wishlist";
import MenuSections from "./menu-sections";
import PageLoader from "../../shared/page-loader";
const Login = loadable(() => import("../login"), { ssr: true });
const WarrantyPopup = loadable(() => import("../warranty-popup/index"), { fallback: <PageLoader /> });
const VideoModal = loadable(() => import("../video-modal/index"), { fallback: <PageLoader /> });
const SellCarPendingActionsModal = loadable(() => import("../sell-car-pending-actions-modal/index"), { fallback: <PageLoader /> });
import SellerPopularCarList from "../seller-popular-car-list/component";

// Constants
import { SELLER_HOME_ROUTE } from "../../../constants/ae/sell-car-online";
import { DOWNLOAD_APP_URL, IDENTIFIERS, DOWNLOAD_APP_URL_IOS, HOW_TO_SELL_URL, SIDEBAR_POPUP, NUMBER, GA_EVENT_PAGE_SOURCE, LOCATION_SOURCE } from "../../../constants/app-constants";
import { ROUTE_NAME, EDIT_PROFILE_ROUTE, HELP_CENTER_ROUTE, HOW_TO_BUY_ROUTE, USER_REVIEWS_ROUTE, ROUTE_PATHS, EPIC_CAR_SALE } from "../../../constants/routes-constants";
import { SOURCES } from "../login/constants";
import { BLOGS_URL } from "../../ae.mobile/footer/constants";
import { STICKY_TABS_VARIANT } from "../car-loan-home/constant";
import CloseButton from "../../shared/icon-cross";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";
import { b2cAllPageEvents } from "../../../tracking/msite/b2c-all-page-events";
import { b2cListingPageEvents } from "../../../tracking/msite/b2c-listing-page-events";
import { ROUTE_TYPE } from "../car-care/constants";

const componentType = {
    [SIDEBAR_POPUP.HOW_TO_SELL]: VideoModal,
    [SIDEBAR_POPUP.WARRANTY]: WarrantyPopup,
    [SIDEBAR_POPUP.SELL_ORDER]: SellCarPendingActionsModal
};

export const EVENT_LABEL_MAP = {
    [STICKY_TABS_VARIANT.LOAN_ESTIMATE]: "loan_calculator",
    [STICKY_TABS_VARIANT.LOAN_PROCESS]: "loan_process",
    [STICKY_TABS_VARIANT.FAQS]: "loan_faq"
};

const MenuSidebar = ({
    routeName,
    isVisible,
    setShowSidebar,
    selectedCity,
    isLoggedIn,
    name,
    cityList,
    setComparingActiveConnect,
    secureToken,
    wishlistedCarsList,
    fetchWishlistedCarsListConnect,
    activeSales,
    reloadCarListConnect,
    activeSaleConfig
}) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const hideSidebar = () => setShowSidebar(false);
    const [loginSource, setLoginSource] = useState(null);
    const [showPopularCars, setShowPopularCars] = useState(false);
    const [showPopularSellCars, setShowPopularSellCars] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isOfferActive, setIsOfferActive] = useState(false);
    const [isCarLoanActive, setIsCarLoanActive] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupType, setPopupType] = useState(null);
    const { getLinkProps } = useLink();
    const ref = useRef(0);
    const { ramadan = {} } = activeSales || {};
    const { customPush } = useCustomHistoryOperations();
    const SUBSCRIPTION_ROUTE = generateCarCareRoute(ROUTE_TYPE.INTRO);
    const VEHICLE_SERVICING_ROUTE = `/${ROUTE_PATHS.carServicing}/`;
    const BANK_VALUATION_CERTIFICATION_ROUTE = `/${ROUTE_PATHS.bankValuationCertificate}/`;

    const { loginMandatory: isLoginMandatoryForSale, active: isSaleActive = false } = activeSaleConfig || {};
    const isDetailPage =  routeName === ROUTE_NAME.DETAIL_PAGE;
    const showCrossIcon = isDetailPage;

    const fetchWishlistedCars = async () => {
        if (isLoggedIn && secureToken && !wishlistedCarsList?.length) {
            await yieldToMainThread();
            fetchWishlistedCarsListConnect(secureToken);
        }
    };
    useEffect(() => {
        // wishlistedCarsList is array [] by default.
        fetchWishlistedCars();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, secureToken]);

    const onClickChange = async () => {
        hideSidebar();
        await customPush(`/location-capture/?from=${routeName === ROUTE_NAME.LISTING ? LOCATION_SOURCE.LISTING : ""}`);
        trackPlatformCustomEventsAEV2(b2cAllPageEvents.locationPageShown, { eventLabel: "menu" });
    };

    const onClickMyBookings = async (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (isLoggedIn) {
            hideSidebar();
            await customPush("/my-bookings");
        } else {
            setShowLogin(true);
            setLoginSource(SOURCES.MY_BOOKINGS);
        }

        trackPlatformCustomEventsAEV2(b2cAllPageEvents.myBookingClicked);
    };

    const onClickSellOrder = (e) => {

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (isLoggedIn) {
            setPopupType(SIDEBAR_POPUP.SELL_ORDER);
            setShowPopup(true);
        } else {
            setShowLogin(true);
            setLoginSource(SOURCES.SELLER_CENTER);
        }
    };

    const onClickLoginSignup = async (e) => {
        e.preventDefault();
        setShowLogin(true);
        setLoginSource(SOURCES.MENU);
        await yieldToMainThread();
        trackPlatformCustomEventsAEV2(b2cAllPageEvents.loginClicked);
    };

    const onClickWishlist = async (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (isLoggedIn) {
            hideSidebar();
            // showWishlistPopupConnect(true);
            await customPush(`/${ROUTE_PATHS.wishlist}`);
        } else {
            setShowLogin(true);
            setLoginSource(SOURCES.WISHLIST);
        }
        trackPlatformCustomEventsAEV2(b2cAllPageEvents.wishlistClicked);
    };

    const getPageRouteName = () => {
        if (routeName === ROUTE_NAME.HOME) {
            return GA_EVENT_PAGE_SOURCE.HOME_PAGE;
        }
        if (routeName === ROUTE_NAME.LISTING) {
            return GA_EVENT_PAGE_SOURCE.LISTING_PAGE;
        }
        if (routeName === ROUTE_NAME.DETAIL_PAGE) {
            return GA_EVENT_PAGE_SOURCE.DETAIL_PAGE;
        }
        return "NA";
    };

    const onClickHelpCenter = async (e) => {
        await yieldToMainThread();
        e.preventDefault();
        hideSidebar();

        let path = "/help-center?";
        if (window?.location?.pathname === SELLER_HOME_ROUTE) {
            path += `source=ae.sell-online&type=${IDENTIFIERS.SELL}`;
        } else path += "source=ae.menu";
        await customPush(path);
        trackPlatformCustomEventsAEV2(b2cAllPageEvents.faqClicked, {
            eventLabel: getPageRouteName()
        });
    };

    const onClickEditProfile = async (e) => {
        await yieldToMainThread();
        e.preventDefault();
        hideSidebar();
        await customPush(`/profile?source=${routeName}`);
        trackPlatformCustomEventsAEV2("editProfileClicked");
    };

    const onSellUsedCar = () => {
        setShowPopularSellCars(true);
    };

    const onClickVehicleServicing = () => {
        trackPlatformCustomEventsAEV2(b2cAllPageEvents.tofScMenu, { eventLabel: "tof_SC_click" });
    };

    const onClickCarComparison = async () => {
        await yieldToMainThread();
        setComparingActiveConnect(true);
        const filters = new Filters({ cityCode: selectedCity.code, cityName: selectedCity.name });
        const { relativeURL } = filters.getListingPayload({ selectedFilters: [], filters: {} });
        hideSidebar();
        await customPush(relativeURL);
        trackPlatformCustomEventsAEV2(b2cAllPageEvents.b2cActivateCompare);
        trackPlatformCustomEventsAEV2(b2cListingPageEvents.compareCtaClick, { eventLabel: "menu" });
    };

    const onClickBlogs = () => {
        window.location.href = BLOGS_URL;
    };

    const hideLogin = async (loginData) => {
        if (loginSource === SOURCES.WISHLIST) {
            if (loginData !== undefined) {
                await customPush(`/${ROUTE_PATHS.wishlist}`);
            }
        } else if (loginSource === SOURCES.SELLER_CENTER) {
            setPopupType(SIDEBAR_POPUP.SELL_ORDER);
            setShowPopup(true);
        } else if (loginSource === SOURCES.MY_BOOKINGS) {
            hideSidebar();
            await customPush("/my-bookings");
        }

        if (routeName === ROUTE_NAME.LISTING && isSaleActive && isLoginMandatoryForSale) {
            reloadCarListConnect(true);
        }
        setShowLogin(false);
    };

    useEffect(() => {
        if (isVisible) {
            document.body.className = "body-scroll-lock";
        }
        return () => { document.body.className = ""; };
    });

    const handleOnClick = (type) => {
        setPopupType(type);
        setShowPopup(true);
    };

    const handleTestimonialsClick = async () => {
        await customPush(USER_REVIEWS_ROUTE);
    };
    const handleHowToBuyClick = async () => {
        await customPush(HOW_TO_BUY_ROUTE);
    };

    const hidePopup = () => {
        setShowPopup(false);
    };
    // ON Left to right swipe close menu
    const handleTouchStart = (touchStartEvent) => {
        const [firstTouch] = touchStartEvent?.touches || [];
        ref.current = firstTouch?.clientX;
    };

    const handleTouchEnd = (touchEndEvent) => {
        const [lastTouch] = touchEndEvent?.changedTouches || [];
        if ((lastTouch?.clientX - ref.current) > NUMBER.TEN) {
            hideSidebar();
        }
    };

    const handleHowToSellClick = (e) => {
        e.preventDefault();
        handleOnClick(SIDEBAR_POPUP.HOW_TO_SELL);
    };

    const onClickOffers = async (isPartnerOfferPage) => {

        await customPush(`${EPIC_CAR_SALE}${isPartnerOfferPage ? "?isPartnerOfferPage=true" : ""}`);

        trackPlatformCustomEventsAEV2(b2cAllPageEvents.menuOfferClick, {
            eventLabel: isPartnerOfferPage ? "partner_offers" : "bank_offers"
        });
    };

    const init = async () => {
        await yieldToMainThread();
        const ele = document.querySelector("#touchOverlay");
        if (ele) {
            await yieldToMainThread();
            ele.addEventListener("touchstart", handleTouchStart, { passive: true });
            ele.addEventListener("touchend", handleTouchEnd, { passive: true });
        }
        return () => {
            ele.removeEventListener("touchstart", handleTouchStart, { passive: true });
            ele.removeEventListener("touchend", handleTouchEnd, { passive: true });
        };
    };
    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const menuPublicElements = [
        { action: handleOnClick.bind(null, SIDEBAR_POPUP.WARRANTY), text: "Warranty", icon: WarrantyIcon },
        { action: onClickCarComparison, text: "Car compare", icon: CarComparison },
        { action: onClickBlogs, text: "Blogs", icon: BlogIcon },
        { action: handleTestimonialsClick, text: "Customer testimonials", icon: TestimonialIcon, url: USER_REVIEWS_ROUTE },
        { action: onClickHelpCenter, text: "Help center", icon: NeedHelpIcon, url: HELP_CENTER_ROUTE }
    ];

    const wishListedCars = isLoggedIn && wishlistedCarsList?.length;
    const menuPrivateElements = [
        { onClick: onClickMyBookings, url: "my-bookings", text: "My Bookings", icon: MyBookingIcon, loginFor: SOURCES.MY_BOOKINGS },
        { onClick: onClickSellOrder, text: "Seller center", icon: SellOrderIcon, loginFor: SOURCES.SELLER_CENTER },
        { onClick: onClickWishlist, text: "Wishlist", icon: WishlistIcon, loginFor: SOURCES.WISHLIST, subText: wishListedCars ? `${wishListedCars} ${wishListedCars === NUMBER.ONE ? "Car" : "Cars"}` : "" }
    ];

    const renderPopup = () => {
        const PopupComponent = componentType[popupType];
        if (popupType === SIDEBAR_POPUP.HOW_TO_SELL) return <PopupComponent url={HOW_TO_SELL_URL} onClose={hidePopup} />;
        return <PopupComponent isPopup={true} onClose={hidePopup} />;
    };

    const menuSectionListOne = [
        { action: onSellUsedCar, text: "Sell car", subText: "We buy your cars", icon: SellIcon, url: SELLER_HOME_ROUTE, isNew: false, isArrow: true }
    ];

    const menuSectionListTwo = [
        { action: onClickVehicleServicing, text: "Vehicle servicing", subText: "Car repair & maintenance", icon: ServiceContractIcon, url: VEHICLE_SERVICING_ROUTE, isArrow: false, isNew: false }
    ];

    const menuSectionListThree = [
        { action: () => { trackPlatformCustomEventsAEV2(subscriptionEvents.subscriptionMenuClick); }, text: "CARS24 Care Plan", subText: "Complete car care from your home", icon: ServiceContractIcon, url: SUBSCRIPTION_ROUTE, isArrow: false, isNew: true },
        { action: () => {}, text: "Bank valuation certificate", subText: "Staring from AED 299 only", icon: BankValuationReportIcon, url: BANK_VALUATION_CERTIFICATION_ROUTE, isArrow: false, isNew: true }
    ];

    const handleCarLoanGaEvents = (e) => {
        const navId = e?.target?.id || "";
        if (navId) {
            trackPlatformCustomEventsAEV2(b2cAllPageEvents.carAffordabilityCtaRoute, {
                eventLabel: EVENT_LABEL_MAP?.[navId]
            });
            trackPlatformCustomEventsAEV2(b2cAllPageEvents.carAffordabilityCarLoanEntry, { eventLabel: "menu" });
        }
    };
    return (
        <div style={{ contentVisibility: isVisible ? "visible" : "hidden" }}>
            <div styleName={`styles.loginWrap ${isVisible ? "styles.slideIn" : "styles.slideOut"}`}>
                <div styleName="styles.overlayTouch" id="touchOverlay" onClick={hideSidebar} />
                <div styleName={`styles.backgroundBlur styles.fullWidth ${isDetailPage ? "styles.zeroPb" : ""}`}>
                    <div styleName={"styles.header"}>
                        {!isLoggedIn ? (
                            <div styleName={"styles.headerLeft"}>
                                {showCrossIcon && (<CloseButton type="orange" onClickHandler={hideSidebar} />)}
                                <div>
                                    <h4>Hi Guest</h4>
                                    <p>For better experience </p>
                                </div>
                            </div>
                        ) : ""}

                        <React.Fragment
                        >
                            {isLoggedIn ?
                                <span styleName={"styles.profile"}>
                                    <span>
                                        {showCrossIcon && (<CloseButton type="orange" onClickHandler={() => { hideSidebar(); }} />)}
                                    </span>
                                    <div>
                                        Hi, {name}
                                        <small styleName={"styles.editProfile"}>
                                            <a {...getLinkProps(EDIT_PROFILE_ROUTE, { onClickHandler: onClickEditProfile })}>Edit details</a>
                                        </small>
                                    </div>
                                </span> :
                                <React.Fragment>
                                    <span onClick={onClickLoginSignup} styleName={"styles.profile styles.loginButton"}>Login</span>
                                   {showLogin && loginSource === SOURCES.MENU && <Login isVisible={showLogin && loginSource === SOURCES.MENU}
                                        onSuccess={hideLogin}
                                        onClose={hideLogin}
                                        loginFor={SOURCES.MENU} />}
                                </React.Fragment>
                            }
                        </React.Fragment>

                        <span styleName={"styles.closeMenu"}>
                            <LazyImage src={WhiteCrossIcon} onClick={hideSidebar} alt="white cross" width={16} height={16} />
                        </span>
                    </div>
                    <div styleName={"styles.changeLocation"}>
                        <strong styleName={"styles.cityName"}> <LazyImage src={mapPing} alt="Location" width={20} height={20} /> {selectedCity.name}</strong>
                        <span onClick={onClickChange} styleName={"styles.change"}>Change</span>
                    </div>
                    <div styleName={"styles.profileWrap"}>
                        <div styleName={"styles.activityBtn"}>
                            <h4 styleName={"styles.activity"}>Your activity</h4>
                            {(menuPrivateElements || []).map(({ onClick, text, icon, loginFor, subText, url }, idx) => (
                                <React.Fragment key={idx}>
                                    {showLogin && loginSource === loginFor && <Login isVisible={showLogin && loginSource === loginFor}
                                        loginFor={loginFor} loginSource={`Please login to view your ${loginFor}`}
                                        onSuccess={hideLogin} onClose={hideLogin} />}
                                    <p styleName={"styles.textPlace"} onClick={onClick}>
                                        <LazyImage src={icon} alt={text} width={24} height={24} />
                                        {url && <a {...getLinkProps(url, { onClickHandler: onClick })}>
                                            {text}
                                        </a>}
                                        {!url && <span styleName={"styles.textPlace"}>{text}</span>}
                                        {subText && <span styleName={"styles.subtextMyactivity"}>{subText}</span>}
                                    </p>
                                </React.Fragment>
                            ))}
                        </div>
                        <div styleName={"styles.sectionSeparator"} />
                        <MenuSections menuSectionList={menuSectionListOne} />
                        <ul styleName={"styles.userHelp"}>
                            <li styleName={"styles.buyUsedCar"}>
                                <div styleName="styles.title" onClick={() => setShowPopularCars(!showPopularCars)}>
                                    <span styleName={"styles.navIconLeft"}><LazyImage src={BuyIcon} alt="buy icon" width={24} height={24} /></span>
                                    <div>
                                        <span>Buy used car</span>
                                        <small>Browse the best used cars</small>
                                    </div>

                                    <span styleName={"styles.arrowWrap"}>
                                        <ArrowIcon rotateBy={showPopularCars ? -NUMBER.NINETY : NUMBER.NINETY} />
                                    </span>
                                </div>
                                <div styleName={`${showPopularCars ? "styles.elementVisible" : "styles.elementHidden"}`}>
                                    <PopularCarList cities={cityList} />
                                </div>
                            </li>
                        </ul>
                        <MenuSections menuSectionList={menuSectionListTwo} />

                        <ul styleName={"styles.userHelp"}>
                            <li>
                                <div styleName="styles.title" onClick={() => setIsCarLoanActive(!isCarLoanActive)}>
                                    <span styleName={"styles.navIconLeft"}><LazyImage src={CarLoanIcon} alt="Car finance" width={24} height={24} /></span>
                                    <span>Car finance</span>
                                    <span styleName={"styles.arrowWrap"}>
                                        <ArrowIcon rotateBy={isCarLoanActive ? -NUMBER.NINETY : NUMBER.NINETY} />
                                    </span>
                                </div>
                                {isCarLoanActive &&
                                    <div styleName="styles.content" onClick={handleCarLoanGaEvents}>
                                        <a id={STICKY_TABS_VARIANT.LOAN_ESTIMATE} href={`/${ROUTE_PATHS.carLoan}?goTo=${STICKY_TABS_VARIANT.LOAN_ESTIMATE}`}>Car loan calculator</a>
                                        <div styleName={"styles.separator"} />
                                        <a id={STICKY_TABS_VARIANT.LOAN_PROCESS} href={`/${ROUTE_PATHS.carLoan}?goTo=${STICKY_TABS_VARIANT.LOAN_PROCESS}`}>Car loan process</a>
                                        <div styleName={"styles.separator"} />
                                        <a id={STICKY_TABS_VARIANT.FAQS} href={`/${ROUTE_PATHS.carLoan}?goTo=${STICKY_TABS_VARIANT.FAQS}`}>FAQs</a>
                                    </div>
                                }
                            </li>
                        </ul>
                        <MenuSections menuSectionList={menuSectionListThree} />
                        <div styleName={"styles.sectionSeparator"} />
                        <ul styleName={"styles.userHelp"}>
                            {!!ramadan?.active && <li>
                                <div styleName="styles.title" onClick={() => setIsOfferActive(!isOfferActive)}>
                                    <span styleName={"styles.navIconLeft"}><LazyImage src={OfferIcon} alt="faq" width={24} height={24} /></span>
                                    <span>Offers</span>
                                    <span styleName={"styles.arrowWrap"}>
                                        <ArrowIcon rotateBy={isOfferActive ? -NUMBER.NINETY : NUMBER.NINETY} />
                                    </span>
                                </div>
                                {isOfferActive &&
                                    <div styleName="styles.content">
                                        <a {...getLinkProps(`${EPIC_CAR_SALE}`, { onClickHandler: onClickOffers.bind(null, false) })} >Bank offers</a>
                                        <div styleName={"styles.separator"} />
                                        <a {...getLinkProps(`${EPIC_CAR_SALE}?isPartnerOfferPage=true`, { onClickHandler: onClickOffers.bind(null, true) })}>Partner offers</a>
                                    </div>
                                }
                            </li>}
                            <li>
                                <div styleName="styles.title" onClick={() => setIsActive(!isActive)}>
                                    <span styleName={"styles.navIconLeft"}><LazyImage src={HowWorkIcon} alt="faq" width={24} height={24} /></span>
                                    <span>How we work</span>
                                    <span styleName={"styles.arrowWrap"}>
                                        <ArrowIcon rotateBy={isActive ? -NUMBER.NINETY : NUMBER.NINETY} />
                                    </span>
                                </div>
                                {isActive &&
                                    <div styleName="styles.content">
                                        <a {...getLinkProps(HOW_TO_BUY_ROUTE, { onClickHandler: handleHowToBuyClick })}>How to Buy from CARS24</a>
                                        <div styleName={"styles.separator"} />
                                        <a href={HOW_TO_SELL_URL} onClick={handleHowToSellClick}>How to sell to CARS24</a>
                                    </div>
                                }
                            </li>
                            {(menuPublicElements || []).map(({ action, icon, text, url }, idx) => (
                                <li key={idx}>
                                    {!url && <div styleName={"styles.textWithoutUrl"} onClick={action}>
                                        <span styleName={"styles.navIconLeft"}>
                                            <LazyImage src={icon} alt={text} width={24} height={24} />
                                        </span>
                                        {text}
                                    </div>}
                                    {url && <a {...getLinkProps(url, { onClickHandler: action })}>
                                        <span styleName={"styles.navIconLeft"}>
                                            <LazyImage src={icon} alt={text} width={24} height={24} />
                                        </span>
                                        {text}
                                    </a>}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div styleName={"styles.downloadApp"}>
                        <h3>Download app for best experience</h3>
                        <p>Only app you need that makes buying and selling a car an easy & quick experience</p>
                        <ul>
                            <li>
                                <a href={DOWNLOAD_APP_URL_IOS} target="_blank" rel="noopener noreferrer" >
                                    <LazyImage
                                        src={AppStoreIcon}
                                        alt="App Store"
                                        width={103}
                                        height={29}
                                    />
                                </a>
                            </li>
                            <li>
                                <a href={DOWNLOAD_APP_URL} target="_blank" rel="noopener noreferrer" >
                                    <LazyImage
                                        src={PlayStoreIcon}
                                        alt="Play Store"
                                        width={102}
                                        height={29}
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div styleName={`styles.seoLinksWrapper ${showPopularSellCars ? "styles.seoLinksActive" : ""}`}>
                <SellerPopularCarList onClose={setShowPopularSellCars.bind(null, false)} />
            </div>
            {showPopup && renderPopup()}
        </div>
    );
};

MenuSidebar.propTypes = {
    isVisible: PropTypes.bool,
    setShowSidebar: PropTypes.func,
    selectedCity: PropTypes.object,
    history: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    secureToken: PropTypes.string,
    wishlistedCarsList: PropTypes.array,
    name: PropTypes.string,
    showWishlistPopupConnect: PropTypes.func,
    cityList: PropTypes.array,
    setComparingActiveConnect: PropTypes.func,
    fetchWishlistedCarsListConnect: PropTypes.func,
    routeName: PropTypes.string,
    activeSales: PropTypes.object,
    reloadCarListConnect: PropTypes.func,
    activeSaleConfig: PropTypes.object
};

export default withWishlist(MenuSidebar);
