export const STICKY_TABS_VARIANT = {
    LOAN_ESTIMATE: "loanEstimate",
    DOCUMENTS: "documents",
    LOAN_PROCESS: "loanProcess",
    ZERO_DOWNPAYMENT: "zeroDownpayment",
    FAQS: "faqs",
    HAPPY_CUSTOMERS: "happyCustomers"
};

export const EVENT_LABEL_MAP = {
    [STICKY_TABS_VARIANT.LOAN_ESTIMATE]: "loan_calculator",
    [STICKY_TABS_VARIANT.DOCUMENTS]: "documents",
    [STICKY_TABS_VARIANT.LOAN_PROCESS]: "loan_process",
    [STICKY_TABS_VARIANT.ZERO_DOWNPAYMENT]: "zero_dp",
    [STICKY_TABS_VARIANT.FAQS]: "faq",
    [STICKY_TABS_VARIANT.HAPPY_CUSTOMERS]: "testimonial"
};

export const CAR_LOAN_FAQS = [
    { question: "Who is eligible for 0 Downpayment?", answer: "Everyone who is eligible for a loan is also eligible for a Zero downpayment. Once you apply for a loan, the bank will assess your eligibility basis your monthly income, DBR and other factors, and finance upto 80% of the total vehicle cost." },
    { question: "Will I get loan if I already have a running loan?", answer: "Yes. You can still apply for a loan if you already have a running loan. However, in this case, the maximum monthly EMI you can avail will change depending on the running loan monthly EMI. You can check your eligibility in our calculator section above." },
    { question: "Can I get loan on someone else's name?", answer: "Yes. If unfortunately, you do not meet the requirements for the loan, an immediate family member can apply for a loan for your car." },
    { question: "What is interest rate for the loan?", answer: "Interest rate is a rate that the bank charges on the loan availed by the customer, and is calculated on the full original loan amount for the whole term without taking into consideration the periodic payments. In other words, interest is charged on the full amount of the loan throughout its loan tenure.<br/><br/> The interest rates are different for auto loans and personal loans and may vary from month to month. Your Relationship Manager will help you with the applicable interest rates based on your profile and choice of bank." },
    { question: "What is the minimum salary requirement for loan?", answer: "The minimum salary requirement for a loan depends on the individual banks. Generally, it varies from AED 3000-6000 per month depending on if you are salaried/self-employed, and a banking/non-banking customer with the bank etc." }
];

export const DOCUMENT_REQUIRED_DATA = [
    { title: "General documents", features: ["Passport", "Emirates ID", "Visa", "Consent Form 1 & 2 (AECB Form)"]},
    { title: "For salaried customers ", features: ["3 months bank statement", "3 months payslip ", "Salary certificate" ]},
    { title: "For self-employed customers ", features: ["Trade licence", "MoA (Memorandum of Association) ", "PoA (Power of Attorney) for Authorised Signatory", "VAT Certificate", "6 month bank statement"]}
];

export const LOAN_PROCESS_DATA = [
    { title: "Select a car you wish to purchase ", description: "Once your test drive is completed pay token amount to reserve that car. " },
    { title: "Upload Documents", description: "Share the documents requested by the bank to submit your loan application." },
    { title: "Receive daily updates on loan application", description: "Our Finance team will work with your preferred bank to fast-track approval." }
];
