export const ROUTE_TYPE = {
    INTRO: "intro",
    CAR_DETAILS: "car-details",
    PLAN_LIST: "plan-list",
    ORDER_SUMMARY: "order-summary",
    TERMS_CONDITIONS: "terms-and-conditions",
    PAYMENT_PROCESSOR: "payment-processor"
};

export const ROUTE_SUB_TYPE = {
    INTRO: {},
    CAR_DETAILS: {
        Brand: "brand",
        Model: "model",
        Year: "year",
        Specification: "specification",
        Emirate: "emirate",
        Odometer: "odometer-range-reading"
    },
    PLAN_LIST: {
        FETCHING_PLANS: "fetching-plans",
        COMING_SOON: "coming-soon",
        SELECT_PLAN: "select-plan",
        ORDER_CREATION: "order-creation"
    },
    ORDER_SUMMARY: {
        ANCHOR_PAGE: "anchor-page",
        ORDER_CREATED: "order-created",
        SUBSCRIPTION_DETAIL: "subscription-detail",
        SUBSCRIPTION_SERVICES_PAGE: "subscription-services-page",
        SUBSCRIPTION_SERVICE_REQUESTS_HISTORY: "subscription-service-requests-history",
        SUBSCRIPTION_SERVICE_REQUEST_DETAILS: "subscription-service-request-details",
        RAISE_SERVICE_REQUEST: "raise-service-request",
        TRANSACTION_HISTORY: "transaction-history",
        RE_ACTIVATE_SUBSCRIPTION: "re-activate-subscription",
        PLAN_SETTINGS: "plan-settings",
        PLAN_CORRECTION: "plan-correction",
        NOT_ELIGIBLE_FOR_SUBSCRIPTION: "not-eligible-for-subscription"
    },
    TERMS_CONDITIONS: {},
    PAYMENT_PROCESSOR: {}
};

export const CAR_CARE_FAQ_CATEGORY = "CARS24 Care Plan";

export const CAR_CARE_PAYMENT_RETURN_URL = "CAR_CARE_PAYMENT_RETURN_URL";

export const SUBSCRIPTION_ORDER_ACTIVITY_STATUS = {
    // Non-Payment related status
    CREATED: "CREATED",
    SELECTED: "SELECTED",
    DOCUMENT_SUBMITTED: "DOCUMENT_SUBMITTED",
    DOCUMENT_APPROVED: "DOCUMENT_APPROVED",
    ACTIVE: "ACTIVE",
    IN_ACTIVE: "IN_ACTIVE",
    CANCELLED: "CANCELLED",
    PAUSED: "PAUSED",
    COMPLETED: "COMPLETED",
    // Payment related status
    PAID: "PAID",
    PENDING: "PENDING",
    FAILED: "FAILED",
    REFUNDED: "REFUNDED"
};

export const SUBSCRIPTION_SERVICE_REQUEST_STATUS = {
    PENDING: "PENDING",
    IN_PROGRESS: "IN_PROGRESS",
    CANCELLED: "CANCELLED",
    REJECTED: "REJECTED",
    COMPLETED: "COMPLETED"
};

export const SUBSCRIPTION_PAYMENT_STATUS = {
    SUCCESS: "SUCCESS",
    PENDING: "PENDING",
    FAILED: "FAILED",
    REFUND: "REFUND"
};

export const SUBSCRIPTION_PLAN_NOT_ACTIVE_STATUSES = [
    SUBSCRIPTION_ORDER_ACTIVITY_STATUS.PAID,
    SUBSCRIPTION_ORDER_ACTIVITY_STATUS.DOCUMENT_SUBMITTED,
    SUBSCRIPTION_ORDER_ACTIVITY_STATUS.DOCUMENT_APPROVED,
    SUBSCRIPTION_ORDER_ACTIVITY_STATUS.REFUNDED,
    SUBSCRIPTION_ORDER_ACTIVITY_STATUS.CANCELLED
];

export const SUBSCRIPTION_SERVICES = {
    SCHEDULE_CAR_SERVICE: "scheduleCarService"
};

