import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Component
import ArrowIcon from "../../shared/arrow/index";
import LazyImage from "../../shared/lazy-image";

// Hook
import useLink from "../../../hooks/use-link";

const MenuSections = ({
    menuSectionList = []
}) => {
    const { getLinkProps } = useLink();

    return (
        <div styleName={"styles.linkWrap"}>
            {(menuSectionList || []).map(({ action, text, subText, icon, isArrow, url, isNew }, index) => (
                <p key={index} onClick={action}>
                    <span styleName={"styles.navIconLeft"}>
                        <LazyImage src={icon} alt={text} width={24} height={24} />
                    </span>
                    <a {...getLinkProps(url)} styleName={"styles.linkHeading"}>
                        {text}
                        <span styleName={"styles.linkDesc"}>{subText}</span>
                    </a>
                    {isArrow && <span styleName={"styles.arrowWrap"}><ArrowIcon rotateBy={0} /></span>}
                    {isNew && <span styleName={"styles.newTag"}>New</span>}
                </p>
            ))}
        </div>
    );
};

MenuSections.propTypes = {
    menuSectionList: PropTypes.array
};

export default MenuSections;
