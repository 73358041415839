import React from "react";
import PropTypes from "prop-types";

// Styling
import styles from "./styles.css";

// Components
import Accordion from "../../shared/accordion";
import ArrowIcon from "../../shared/arrow";

// Constants
import { DEFAULT_AE_CITY_NAME, GLOBAL_URL, NUMBER } from "../../../constants/app-constants";
import { appUrl } from "../../../constants/url-constants";
import { DEFAULT_MAKE_MODELS } from "../../../utils/filters-v2/constants";

// Utils
import dashedLowercase from "../../../utils/helpers/dashed-lowercase";
import Filters from "../../../utils/filters-v2";

const PopularCarList = ({
    cities = [],
    allFilters = {},
    clearAllFiltersConnect,
    selectedCity,
    reloadCarListConnect,
    history
}) => {

    const BASE_URL = `${appUrl()}/buy-used-`;
    const makeArray = allFilters?.make?.options?.slice(NUMBER.ZERO, NUMBER.TEN) || [];

    const onAnchorClick = () => {
        clearAllFiltersConnect();
    };

    const handleGlobalUrl = (e) => {
        e.preventDefault();
        clearAllFiltersConnect();
        reloadCarListConnect(true);
        const filters = new Filters({ cityCode: selectedCity?.code, cityName: selectedCity?.name });
        const { relativeURL } = filters.getListingPayload({ selectedFilters: [], filters: {}, isGlobalUrl: true, hasCitySkip: selectedCity?.hasCitySkip });
        history.push(relativeURL);
    };

    return (
        <div styleName={"styles.locationModal"}>
            <div styleName={"styles.outer"}>
                <Accordion key={"expandable-city"} totalItems={NUMBER.THREE}>
                    {(({ onClick, currentVisibleStates }) => (
                        <React.Fragment>
                            <Accordion.Content >
                                <Accordion.Heading>
                                    <div styleName={""}>
                                        <h5 styleName={"styles.cardTitle"} onClick={() => onClick({ index: NUMBER.ZERO })}>
                                            Used cars for sale by Makes
                                            <ArrowIcon styleName={"styles.arrowWrap"} rotateBy={currentVisibleStates[NUMBER.ZERO] ? NUMBER.TWO_HUNDRED_SEVENTY : NUMBER.NINETY} />
                                        </h5>
                                        <Accordion.Body>
                                            <div styleName={`styles.collapse ${currentVisibleStates[NUMBER.ZERO] ? "" : "styles.hide"}`}>
                                                <div styleName={"styles.popularModalWrapper"}>
                                                    <ul>
                                                        {(makeArray || []).map((make, index) => (
                                                            <li key={`${make}${index}`}>
                                                                <a onClick={onAnchorClick} href={`${BASE_URL}${dashedLowercase(make.displayText)}-cars-${dashedLowercase(DEFAULT_AE_CITY_NAME)}/`}>
                                                                    Used {make.displayText} cars in  {DEFAULT_AE_CITY_NAME}
                                                                </a>
                                                            </li>
                                                        ))}
                                                        <li>
                                                            <a onClick={onAnchorClick} href={`${BASE_URL}cars-${dashedLowercase(DEFAULT_AE_CITY_NAME)}/`}>All used cars in {DEFAULT_AE_CITY_NAME}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </div>
                                </Accordion.Heading>
                            </Accordion.Content>
                            <Accordion.Content >
                                <Accordion.Heading>
                                    <div styleName={""}>
                                        <h5 styleName={"styles.cardTitle"} onClick={() => onClick({ index: NUMBER.ONE })}>
                                            Used cars for sale by Models
                                            <ArrowIcon styleName={"styles.arrowWrap"} rotateBy={currentVisibleStates[NUMBER.ONE] ? NUMBER.TWO_HUNDRED_SEVENTY : NUMBER.NINETY} />
                                        </h5>
                                        <Accordion.Body>
                                            <div styleName={`styles.collapse ${currentVisibleStates[NUMBER.ONE] ? "" : "styles.hide"}`}>
                                                <div styleName={"styles.popularModalWrapper"}>
                                                    <ul>
                                                        {(DEFAULT_MAKE_MODELS || []).map((makeModel, index) => (
                                                            <li key={`${makeModel}${index}`}>
                                                                <a onClick={onAnchorClick} href={`${BASE_URL}${dashedLowercase(makeModel.make)}-${dashedLowercase(makeModel.model)}-cars-${dashedLowercase(DEFAULT_AE_CITY_NAME)}/`}>
                                                                    Used {makeModel.make} {makeModel.model} cars in  {DEFAULT_AE_CITY_NAME}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </div>
                                </Accordion.Heading>
                            </Accordion.Content>
                            <Accordion.Content >
                                <Accordion.Heading>
                                    <div styleName={""}>
                                        <h5 styleName={"styles.cardTitle"} onClick={() => onClick({ index: NUMBER.TWO })}>
                                            Used cars for sale by Location
                                            <ArrowIcon styleName={"styles.arrowWrap"} rotateBy={currentVisibleStates[NUMBER.TWO] ? NUMBER.TWO_HUNDRED_SEVENTY : NUMBER.NINETY} />
                                        </h5>
                                        <Accordion.Body>
                                            <div styleName={`styles.collapse ${currentVisibleStates[NUMBER.TWO] ? "" : "styles.hide"}`}>
                                                <div styleName={"styles.popularModalWrapper"}>
                                                    <ul>
                                                        <li key={`uae`}>
                                                            <a key="uae" href={GLOBAL_URL} onClick={handleGlobalUrl}>
                                                                Used cars in UAE
                                                            </a>
                                                        </li>
                                                        {(cities || []).map((city, cityIndex) => (
                                                            <li key={`${cityIndex}`}>
                                                                <a key={`${city?.name}${cityIndex}`} href={`${BASE_URL}cars-${dashedLowercase(city?.name)}/`}>
                                                                    Used cars in {city?.name}
                                                                </a>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </div>
                                </Accordion.Heading>
                            </Accordion.Content>
                        </React.Fragment>
                    ))}
                </Accordion>

            </div>
        </div>
    );
};

PopularCarList.propTypes = {
    cities: PropTypes.array,
    allFilters: PropTypes.object,
    clearAllFiltersConnect: PropTypes.func,
    selectedCity: PropTypes.object,
    reloadCarListConnect: PropTypes.func,
    history: PropTypes.object
};

export default PopularCarList;
