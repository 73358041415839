import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MenuSidebar from "./component";
import { setComparingActive } from "../compare-widget/actions";
import { fetchWishlistedCars } from "../my-wishlist/actions";
import { updateLocationPopupShown } from "../../ae.configuration/actions";
import { reloadCarList } from "../car-listing/actions";

const mapStateToProps = ({
    user: {
        isLoggedIn, name
    },
    cities: {
        selectedCity
    },
    config: {
        cityList,
        activeSales,
        activeSaleConfig
    },
    myWishlist: { wishlistedCarsList }
}) => ({
    selectedCity,
    cityList,
    wishlistedCarsList,
    activeSales,
    isLoggedIn,
    name,
    activeSaleConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateLocationPopupShownConnect: updateLocationPopupShown,
    setComparingActiveConnect: setComparingActive,
    fetchWishlistedCarsConnect: fetchWishlistedCars,
    reloadCarListConnect: reloadCarList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MenuSidebar);
