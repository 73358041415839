import { NUMBER } from "../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../constants/routes-constants";
import { ROUTE_SUB_TYPE, ROUTE_TYPE, SUBSCRIPTION_ORDER_ACTIVITY_STATUS } from "./constants";
import qs from "querystring";

export const generateCarCareRoute = (stepType = ROUTE_TYPE.INTRO, subType = "", extraQueryParams) => {
    delete extraQueryParams?.stepType;
    delete extraQueryParams?.subType;
    const queryString = qs.stringify(extraQueryParams);
    return `/${ROUTE_PATHS.carCare}?${stepType ? `stepType=${stepType}` : ""}${subType ? `&subType=${subType}` : ""}${queryString ? `&${queryString}` : ""}`;
};

export const formatOdometerReading = (odoValue) => {

    if (!odoValue) {
        return "";
    }

    if (odoValue.includes("-")) {
        const odoValueParts = odoValue.split("-");
        const odoValuePartsLength = odoValueParts.length;
        odoValue = odoValueParts[odoValuePartsLength - 1];
    }

    return `${Math.floor((odoValue || 0) / NUMBER.THOUSAND)}k`;
};

export const getLastestSubscriptionFromList = (orderList) => {
    if (Array.isArray(orderList) && orderList.length > NUMBER.ZERO) {
        return orderList[NUMBER.ZERO];
    }

    return null;
};

export const getSubscriptionOrderActiveStepName = (stepType, subType) => {
    return `${stepType}-${subType}`;
};

// eslint-disable-next-line complexity, max-statements
export const getSubscriptionOrderActiveStepEndpoint = ({
    stepType,
    subType,
    subscriptionOrderId,
    serviceId,
    serviceKey
}) => {

    if (stepType === ROUTE_TYPE.ORDER_SUMMARY && subType === ROUTE_SUB_TYPE.ORDER_SUMMARY.TRANSACTION_HISTORY) {
        return {
            serviceName: "OMS",
            endPoint: `v1/subscription-orders/${subscriptionOrderId}/payment-history`
        };
    }

    if (stepType === ROUTE_TYPE.ORDER_SUMMARY && subType === ROUTE_SUB_TYPE.ORDER_SUMMARY.SUBSCRIPTION_SERVICE_REQUESTS_HISTORY) {
        return {
            serviceName: "VAS",
            endPoint: `v1/subscription/${subscriptionOrderId}/services`
        };
    }

    if (stepType === ROUTE_TYPE.ORDER_SUMMARY && subType === ROUTE_SUB_TYPE.ORDER_SUMMARY.SUBSCRIPTION_SERVICE_REQUEST_DETAILS) {
        return {
            serviceName: "VAS",
            endPoint: `v1/subscription/service/${serviceId}`
        };
    }

    if (stepType === ROUTE_TYPE.ORDER_SUMMARY && subType === ROUTE_SUB_TYPE.ORDER_SUMMARY.PLAN_SETTINGS) {
        return {
            serviceName: "OMS",
            endPoint: `v1/subscription-orders/${subscriptionOrderId}/plan-settings`
        };
    }

    if (stepType === ROUTE_TYPE.ORDER_SUMMARY && subType === ROUTE_SUB_TYPE.ORDER_SUMMARY.SUBSCRIPTION_DETAIL) {
        return {
            serviceName: "OMS",
            endPoint: `v1/subscription-orders/${subscriptionOrderId}/plan-details`
        };
    }

    if (stepType === ROUTE_TYPE.ORDER_SUMMARY && subType === ROUTE_SUB_TYPE.ORDER_SUMMARY.SUBSCRIPTION_SERVICES_PAGE) {
        return {
            serviceName: "OMS",
            endPoint: `v1/subscription-orders/${subscriptionOrderId}/service-details`
        };
    }

    if (stepType === ROUTE_TYPE.ORDER_SUMMARY && subType === ROUTE_SUB_TYPE.ORDER_SUMMARY.RAISE_SERVICE_REQUEST) {
        return {
            serviceName: "OMS",
            endPoint: `v1/subscription-orders/${subscriptionOrderId}/service-cover`,
            params: { serviceKey }
        };
    }

    if (stepType === ROUTE_TYPE.ORDER_SUMMARY && subType === ROUTE_SUB_TYPE.ORDER_SUMMARY.PLAN_CORRECTION) {
        return {
            serviceName: "OMS",
            endPoint: `v1/subscription-orders/${subscriptionOrderId}/fetch-correct-plan`
        };
    }

    return {
        serviceName: "",
        endPoint: ""
    };
};

//Use this function for parsing the text which contains variables
export const parseText = ({ template, variables = {} }) => {
    return template.replace(/\$\{(.*?)\}/g, (_, key) => variables[key] || "");
};

export const getSubscriptionAnchorPageRedirection  = (status) => {

    const INITIAL_PAYMENT_PAGE_STATUSES = [
        SUBSCRIPTION_ORDER_ACTIVITY_STATUS.CREATED,
        SUBSCRIPTION_ORDER_ACTIVITY_STATUS.SELECTED,
        SUBSCRIPTION_ORDER_ACTIVITY_STATUS.PENDING,
        SUBSCRIPTION_ORDER_ACTIVITY_STATUS.FAILED
    ];

    const SHOW_SERVICES_OFFERED_PAGE_STATUSES = [
        SUBSCRIPTION_ORDER_ACTIVITY_STATUS.ACTIVE,
        SUBSCRIPTION_ORDER_ACTIVITY_STATUS.IN_ACTIVE,
        SUBSCRIPTION_ORDER_ACTIVITY_STATUS.PAUSED
    ];

    if (INITIAL_PAYMENT_PAGE_STATUSES.includes(status)) {
        return ROUTE_SUB_TYPE.ORDER_SUMMARY.ORDER_CREATED;
    } else if (SHOW_SERVICES_OFFERED_PAGE_STATUSES.includes(status)) {
        return ROUTE_SUB_TYPE.ORDER_SUMMARY.SUBSCRIPTION_SERVICES_PAGE;
    } else {
        return ROUTE_SUB_TYPE.ORDER_SUMMARY.SUBSCRIPTION_DETAIL;
    }
};

export const checkIsSubscriptionCancelled = (status) => {
    return status === SUBSCRIPTION_ORDER_ACTIVITY_STATUS.IN_ACTIVE || status === SUBSCRIPTION_ORDER_ACTIVITY_STATUS.CANCELLED;
};

export const calculatePriceDifference = (subscriptionPlan, validPlan) => {
    const { offerMonthlyFee, registrationFee } = subscriptionPlan || {};
    const { offerMonthlyFee: offerMonthlyFeeCorrected, registrationFee: registrationFeeCorrected } = validPlan || {};
    const adjustedAmount  = (offerMonthlyFeeCorrected + registrationFeeCorrected) - (offerMonthlyFee + registrationFee);
    return adjustedAmount;
};

export const isSubscriptionOrderJourneyCompleted = (subscriptionOrderStatus = "") => {

    const isOrderJourneyCompleted = (
        subscriptionOrderStatus === SUBSCRIPTION_ORDER_ACTIVITY_STATUS.CANCELLED
        || subscriptionOrderStatus === SUBSCRIPTION_ORDER_ACTIVITY_STATUS.REFUNDED
        || subscriptionOrderStatus === SUBSCRIPTION_ORDER_ACTIVITY_STATUS.COMPLETED
        || !subscriptionOrderStatus
    );

    return isOrderJourneyCompleted;
};
